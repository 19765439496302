import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Reviews from "../components/Repeating/Reviews";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const [refStep1, inViewStep1] = useInView({ threshold: 0.5 }),
    [refStep2, inViewStep2] = useInView({ threshold: 0.5 }),
    [refStep3, inViewStep3] = useInView({ threshold: 0.5 }),
    [refStep4, inViewStep4] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inViewStep1) {
    }
    if (inViewStep2) {
    }
    if (inViewStep3) {
    }
    if (inViewStep4) {
    }
  });

  return (
    <Layout headerStyle="overlap" marginTop="-64px">
      <SearchEngineOptimization
        title="Realsynch Integration & Automation | How It Works"
        description="Instantly improve your workflow in five easy steps. Realsynch connects your real estate systems and automates tasks from lead to close, saving you time."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 rounded-bl-[60px] bg-primary-900 pt-20 pb-20 md:mb-28 md:rounded-bl-[120px] md:pt-32 md:pb-28 lg:rounded-bl-[220px]">
        <div className="container max-w-[1267px]">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-14 lg:gap-x-28">
            <div className="order-2 md:order-1">
              <h1 className="text-white">Realsynch Integration & Automation</h1>
              <p className="text-white/80 md:mb-8">
                Realsynch automatically performs thousands of data tasks between
                systems, helping busy residential real estate teams streamline
                their workflow. Sign up and start integrating today following
                our simple instructions, or let our team of experts handle the
                setup for you.
              </p>
              <ButtonSolid
                altStyle={2}
                // modal="modal-contact"
                text="Start Free Trial"
                href="/free-trial"
              />
            </div>
            <div className="order-1 text-center md:order-2 md:text-left">
              <StaticImage
                src="../images/2.0 How it Works/1.0 Hero.png"
                loading="eager"
                width={560}
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 overflow-x-hidden md:mb-32">
        <div className="container">
          <header className="mx-auto mb-16 max-w-[600px] text-center md:mb-24">
            <h2>How It Works</h2>
            <p>
              Real estate agents like you use a myriad of software systems.
              Realsynch connects those systems and automates tasks from lead to
              close, saving you time. And you can get started in five simple
              steps.
            </p>
          </header>

          <div className="relative mx-auto max-w-[1267px]">
            <div
              className="absolute left-0 right-0 mx-auto hidden h-full w-[3px] border-l-[3px] border-dashed border-primary-400 md:block"
              // style={{
              //   background: "linear-gradient(to bottom, #51E7FF, #673DEC)",
              // }}
            ></div>

            {/* {Step 1} */}
            <div className="relative mb-16 grid items-center gap-y-8 md:mb-20 md:grid-cols-2 md:gap-x-20 lg:gap-x-40">
              <span class="absolute left-0 right-0 top-0 m-auto h-1/2 w-[3px] bg-white"></span>
              <div className="absolute left-0 right-0 hidden items-center justify-center md:flex">
                <span
                  className="absolute h-4 w-4 rounded-full bg-primary-700"
                  ref={refStep1}
                />
              </div>

              <div
                ref={refStep1}
                className="transition-all duration-300 ease-linear md:flex md:justify-end"
              >
                <StaticImage
                  src="../images/2.0 How it Works/Step_1.png"
                  alt="Step 1: Select Source"
                  width={440}
                  height={440}
                />
              </div>

              <div
                ref={refStep1}
                className="transition-all duration-300 ease-linear"
              >
                <h3 className="mb-3">Step 1: Select Source</h3>
                <p className="mb-0">
                  Choose the system you want to use as your source for
                  retrieving data.
                </p>
              </div>
            </div>

            {/* {Step 2} */}
            <div className="relative mb-16 grid items-center gap-y-8 md:mb-20 md:grid-cols-2 md:gap-x-20 lg:gap-x-40">
              <div className="absolute left-0 right-0 hidden items-center justify-center md:flex">
                <span
                  className="absolute h-4 w-4 rounded-full bg-primary-700"
                  ref={refStep2}
                />
              </div>

              <div
                ref={refStep2}
                className="order-2 transition-all duration-300 ease-linear md:order-1"
              >
                <h3 className="mb-3">Step 2: Choose Source Data Filters</h3>
                <p className="mb-0">
                  Select the data events and activities you want to trigger your
                  integration.
                </p>
              </div>

              <div
                ref={refStep2}
                className="order-1 transition-all duration-300 ease-linear md:order-2"
              >
                <StaticImage
                  src="../images/2.0 How it Works/Step_2.png"
                  alt="Step 2: Choose Source Data Filters"
                  width={440}
                  height={440}
                />
              </div>
            </div>

            {/* {Step 3} */}
            <div className="relative mb-16 grid items-center gap-y-8 md:mb-20 md:grid-cols-2 md:gap-x-20 lg:gap-x-40">
              <div className="absolute left-0 right-0 hidden items-center justify-center md:flex">
                <span
                  className="absolute h-4 w-4 rounded-full bg-primary-700"
                  ref={refStep3}
                />
              </div>

              <div
                ref={refStep3}
                className="transition-all duration-300 ease-linear md:flex md:justify-end"
              >
                <StaticImage
                  src="../images/2.0 How it Works/Step_3.png"
                  alt="Step 3: Choose Your Target"
                  width={440}
                  height={440}
                />
              </div>

              <div
                ref={refStep3}
                className="transition-all duration-300 ease-linear"
              >
                <h3 className="mb-3">Step 3: Choose Your Target</h3>
                <p className="mb-0">
                  Choose the system you want to use as your target for sending
                  data.
                </p>
              </div>
            </div>

            {/* {Step 4} */}
            <div className="relative mb-16 grid items-center gap-y-8 md:mb-20 md:grid-cols-2 md:gap-x-20 lg:gap-x-40">
              <div className="absolute left-0 right-0 hidden items-center justify-center md:flex">
                <span
                  className="absolute h-4 w-4 rounded-full bg-primary-700"
                  ref={refStep4}
                />
              </div>

              <div
                ref={refStep4}
                className="order-2 transition-all duration-300 ease-linear md:order-1"
              >
                <h3 className="mb-3">
                  Step 4: Choose Target Custom Data Filters
                </h3>
                <p>
                  Select the data events and activities you want to execute in
                  your target app.
                </p>
              </div>

              <div
                ref={refStep2}
                className="order-1 transition-all duration-300 ease-linear md:order-2"
              >
                <StaticImage
                  src="../images/2.0 How it Works/Step_4.png"
                  alt="Step 4: Choose Target Data Filters"
                  width={440}
                  height={440}
                />
              </div>
            </div>

            {/* {Step 5} */}
            <div className="relative grid items-center gap-y-8 md:grid-cols-2 md:gap-x-20 lg:gap-x-40">
              <span class="absolute left-0 right-0 bottom-0 m-auto h-1/2 w-[3px] bg-white"></span>
              <div className="absolute left-0 right-0 hidden items-center justify-center md:flex">
                <span
                  className="absolute h-4 w-4 rounded-full bg-primary-700"
                  ref={refStep4}
                />
              </div>

              <div
                ref={refStep4}
                className="order-2 transition-all duration-300 ease-linear md:order-2"
              >
                <h3 className="mb-3">Step 5: Activate Your Integration</h3>
                <p>
                  The source and target systems will sync and begin automating
                  data based on preset filters, triggers, and rules.
                </p>
                <ButtonSolid href="/pricing/" text="Sign Up" />
              </div>

              <div
                ref={refStep4}
                className="order-1 transition-all duration-300 ease-linear md:order-1"
              >
                <StaticImage
                  src="../images/2.0 How it Works/Step_5.png"
                  alt="Step 5: Name Your Integration & Activate"
                  width={440}
                  height={440}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <Reviews />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
